/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #3880ff;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #0cd1e8;
	--ion-color-secondary-rgb: 12, 209, 232;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #0bb8cc;
	--ion-color-secondary-tint: #24d6ea;

	/** tertiary **/
	--ion-color-tertiary: #7044ff;
	--ion-color-tertiary-rgb: 112, 68, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #633ce0;
	--ion-color-tertiary-tint: #7e57ff;

	/** success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/* define custom color theme for ionic components: */
	/** llm_primary **/
	--ion-color-llm_primary: #f16622;
	--ion-color-llm_primary-rgb: 241, 102, 34;
	--ion-color-llm_primary-contrast: #fff;
	--ion-color-llm_primary-contrast-rgb: 255, 255, 255;
	--ion-color-llm_primary-shade: #d45a1e;
	--ion-color-llm_primary-tint: #f27538;

	/** llm_secondary **/
	--ion-color-llm_secondary: #fea000;
	--ion-color-llm_secondary-rgb: 254, 160, 0;
	--ion-color-llm_secondary-contrast: #fff;
	--ion-color-llm_secondary-contrast-rgb: 255, 255, 255;
	--ion-color-llm_secondary-shade: #e08d00;
	--ion-color-llm_secondary-tint: #feaa1a;

	/** llm_warning **/
	--ion-color-llm_warning: #f7981c;
	--ion-color-llm_warning-rgb: 247, 152, 28;
	--ion-color-llm_warning-contrast: #fff;
	--ion-color-llm_warning-contrast-rgb: 0, 0, 0;
	--ion-color-llm_warning-shade: #d98619;
	--ion-color-llm_warning-tint: #f8a233;

	/** llm_success **/
	--ion-color-llm_success: #19bc9b;
	--ion-color-llm_success-rgb: 25, 188, 155;
	--ion-color-llm_success-contrast: #fff;
	--ion-color-llm_success-contrast-rgb: 0, 0, 0;
	--ion-color-llm_success-shade: #16a588;
	--ion-color-llm_success-tint: #30c3a5;

	/** header_button_active **/
	--ion-color-header_button_active: #ff6666;
}

.ion-color-llm_primary {
	--ion-color-base: var(--ion-color-llm_primary);
	--ion-color-base-rgb: var(--ion-color-llm_primary-rgb);
	--ion-color-contrast: var(--ion-color-llm_primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-llm_primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-llm_primary-shade);
	--ion-color-tint: var(--ion-color-llm_primary-tint);
}

.ion-color-llm_secondary {
	--ion-color-base: var(--ion-color-llm_secondary);
	--ion-color-base-rgb: var(--ion-color-llm_secondary-rgb);
	--ion-color-contrast: var(--ion-color-llm_secondary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-llm_secondary-contrast-rgb);
	--ion-color-shade: var(--ion-color-llm_secondary-shade);
	--ion-color-tint: var(--ion-color-llm_secondary-tint);
}

.ion-color-llm_warning {
	--ion-color-base: var(--ion-color-llm_warning);
	--ion-color-base-rgb: var(--ion-color-llm_warning-rgb);
	--ion-color-contrast: var(--ion-color-llm_warning-contrast);
	--ion-color-contrast-rgb: var(--ion-color-llm_warning-contrast-rgb);
	--ion-color-shade: var(--ion-color-llm_warning-shade);
	--ion-color-tint: var(--ion-color-llm_warning-tint);
}

.ion-color-llm_success {
	--ion-color-base: var(--ion-color-llm_success);
	--ion-color-base-rgb: var(--ion-color-llm_success-rgb);
	--ion-color-contrast: var(--ion-color-llm_success-contrast);
	--ion-color-contrast-rgb: var(--ion-color-llm_success-contrast-rgb);
	--ion-color-shade: var(--ion-color-llm_success-shade);
	--ion-color-tint: var(--ion-color-llm_success-tint);
}

.ion-color-header_button_active {
	--ion-color-base: var(--ion-color-header_button_active);
}
